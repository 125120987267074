@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.header {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.title {
  margin-top: 42px;

  font-size: 34px;
  font-weight: 600;
  line-height: 41.65px;
  text-align: left;

  @include xs-block() {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.5px;
  }
}

.description {
  margin-top: 17px;
  margin-bottom: 42px;

  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.005em;
  text-align: justified;

  @include xs-block() {
    margin-top: 6px;
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 20px;
  }
}
