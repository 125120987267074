//Обновленные стили

$black: #060606;
$blackLight: #0e0e0f99;
$BlackBackground: #111112;
$RedMain: #d5070e;

$RedGradientDefault: linear-gradient(0deg, #6b0000 0%, #ff0a13 99.97%);
$RedGradientPressed: linear-gradient(0deg, #660000 0%, #c2050c 99.97%);
$RedGradientHover: linear-gradient(0deg, #4a0000 0%, #c2050c 99.97%);

$GoldGradientDefault: linear-gradient(
  135deg,
  #8c6034 11.69%,
  #a9824f 22.66%,
  #f2d893 46.82%,
  #ffe79f 50.87%,
  #d7b674 64.67%,
  #96662e 88.34%
);
$GoldGradientPressed: linear-gradient(
  135deg,
  #a9824f 11.69%,
  #ffe79f 35.45%,
  #f2d893 57.29%,
  #d7b674 73.77%,
  #96662e 88.34%
);
$GoldGradientHover: linear-gradient(
  135deg,
  #f2d893 11.69%,
  #ffe79f 25.87%,
  #a9824f 50.78%,
  #d7b674 88.34%
);
$GoldGradientText: linear-gradient(
  135deg,
  #8c6034 11.69%,
  #a9824f 22.66%,
  #f2d893 46.82%,
  #ffe79f 50.87%,
  #d7b674 64.67%,
  #96662e 88.34%
);

$BronzeGradientDefault: linear-gradient(
  135deg,
  #8c6034 11.69%,
  #a9824f 22.66%,
  #db9f6e 36.98%,
  #cb9a73 50.87%,
  #a88134 64.67%,
  #96662e 88.34%
);
$BronzeGradientPressed: linear-gradient(
  134.08deg,
  #96662e 11.69%,
  #a88134 35.82%,
  #cb9a73 55.57%,
  #db9f6e 76.89%,
  #a9824f 88.62%,
  #8c6034 101.47%
);
$BronzeGradientHover: linear-gradient(
  131.62deg,
  #8c6034 25.93%,
  #a9824f 42.31%,
  #db9f6e 54.57%,
  #cb9a73 63.79%,
  #cd9b72 75.74%,
  #a88134 88.44%,
  #96662e 100%
);

$SilverGradientDefault: linear-gradient(
  135deg,
  #d9d9d9 11.69%,
  #c2c2c2 22.66%,
  #adadad 36.98%,
  #959595 46.82%,
  #828282 64.67%,
  #727272 88.34%
);
$SilverGradientHover: linear-gradient(
  134.59deg,
  #858585 9.84%,
  #c2c2c2 26.7%,
  #adadad 43.62%,
  #d9d9d9 63.05%,
  #959595 80.76%,
  #828282 90.47%,
  #727272 100.73%
);
$SilverGradientPressed: linear-gradient(
  138.95deg,
  #727272 -5.04%,
  #828282 18.63%,
  #959595 40.52%,
  #adadad 58.01%,
  #c2c2c2 87.52%,
  #d9d9d9 102.99%
);

$PlatinumGradientDefault: linear-gradient(
  146.5deg,
  #d9d9d9 -24.66%,
  #ffffff -5.61%,
  #929090 17.92%,
  #d0d0d0 44.53%,
  #efefef 85.12%,
  #727272 108.41%
);
$PlatinumGradientHover: linear-gradient(
  146.5deg,
  #d9d9d9 -24.66%,
  #ffffff -4.81%,
  #d0d0d0 22.23%,
  #efefef 50.7%,
  #929090 83.7%,
  #727272 108.41%
);
$PlatinumGradientPressed: linear-gradient(
  146.5deg,
  #727272 -24.66%,
  #efefef -1.37%,
  #d0d0d0 28.14%,
  #929090 73.73%,
  #9d9b9b 89.43%,
  #ffffff 101.26%,
  #d9d9d9 108.41%
);

$GreenGradientDefault: #20a153;
$GreenGradientPressed: #1b8645;
$GreenGradientHover: #156b37;

$white: #fff;
$gray: #2a2a2a;
$grayLight: #CFCFD0;
$grayWhite: #c4c4c4;
$grayLightVery: #393939;
// $grayLight: #a4a4a4;

//Стили версии предыдущей версии

$RedMain_filter: #dc000033;
$red: #d5070e;
$Red_black_gradient_1: linear-gradient(90deg, #a30307 0%, #ff0a13 63.65%);
$Red_black_gradient_2: linear-gradient(180deg, #ff0a13 0%, #a30307 63.65%);
$gold: linear-gradient(180deg, #de9f17 -0.11%, #cf9a15 31.91%, #fff68a 67.98%, #ffcf45 99.99%);
$gold_line: linear-gradient(
  134deg,
  #a77327 4.48%,
  #ead07a 27.16%,
  #f8e7b8 48.17%,
  #eed57a 63.85%,
  #c79f4c 80.13%
);
$gold_button: linear-gradient(90deg, #ae7111 0.03%, #ffff8c 32%, #ffe45b 68.03%, #ffef7c 100%);

$GrayDark: #443e3f;
$GreyDark_2: #343031;
$GreyDark_3: #282425;
$GreyLight: #c4c4c41a;
$GreyLight_2: #29252680;
$GreyLight_very: #e8e5e5;
$GrayAccent: #383435;

$GilroyFont: 'Gilroy', sans-serif;
$GilroyThin: 100;
$GilroyLight: 300;
$GilroyRegular: 400;
$GilroyMedium: 500;
$GilroyBold: 700;
$GilroyBlack: 900;
