@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  padding-top: 12px;
  padding-bottom: 20px;

  border-bottom: 1px solid $gray;
  position: relative;
}

.wrapperInBasket {
  padding: 20px;

  border: 1px solid #2A2A2A;
  border-radius: 16px;
  position: relative;

  @include xs-block() {
    border: 0;
    padding: 0 0 20px 0;
    border-bottom: 1px solid $gray !important;
  }

  .value {
    font-size: 16px;
    line-height: 19.50px;

    @include xs-block() {
      font-size: 14px;
      font-weight: 600;
      line-height: 16.98px;
      letter-spacing: 0.02em;
      text-align: left;
    }
  }

  .columnName,
  .grayText {
    font-size: 16px;
    line-height: 19.50px;

    @include xs-block() {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.98px;
      letter-spacing: 0.02em;
      text-align: left;
    }
  }
}

.body {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 14px;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 6px;
}

.row {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.right {
    justify-content: flex-end;
  }
}

.grayText {
  font-weight: 400;
  color: $grayLight;
}

.columnName {
  color: #cfcfd0;
  font-weight: 500;
}

.value {
  color: #fff;
  font-weight: 700;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
  cursor: pointer;
}

.text {
  width: 90%;
  text-align: right;
}

.arrow {
  flex: 0 0 20px;
  transition: all 0.3s ease 0s;
  cursor: pointer;

  &.rotate {
    transform: rotate(180deg);
  }

  svg {
    fill: #888888;
  }
}

.slider {
  overflow: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  right: -10px;
  left: 0;
  width: calc(100% + 10px);
  @include scrollbars(0, inherit, inherit);
  margin-bottom: 12px;
}

.game {
  padding: 11px 10px;
  border-radius: 8px;
  text-wrap: nowrap;
  border: 1px solid $gray;
  color: $grayLight;
  position: relative;

  font-size: 13px;
  font-weight: 600;

  &.active {
    @include gradientText();

    @include gradientBorder();
  }
}
