@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapperCard {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid $gray;
  background: $black;
  // max-width: 280px;
  height: 247px;
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  position: relative;

  &.active {
    .titleBlock {
      span {
        border: 1px solid $red;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          background: $red;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &:hover {
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      border-radius: 10px;
      padding: 0.5px;
      background: linear-gradient(180deg, #fdf7d4 3.79%, #f1af7d 50.15%, #b87548 92.75%);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: destination-out;
    }

    .title {
      background: $GoldGradientDefault;
      color: #000;
    }
  }

  @include sm-block {
    height: 58px;
    max-width: 100%;
    background: inherit;
    border-radius: 0;
    border: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      &::before {
        display: none;
      }

      .title {
        background: transparent;
        color: #fff;
      }
    }
  }

  .titleBlock {
    display: flex;
    gap: 12px;
    align-items: center;
    border-top: 1px solid $gray;

    @include sm-block() {
      border: none;
    }

    span {
      display: none;
      width: 12px;
      height: 12px;
      border: 1px solid $grayLight;
      border-radius: 100%;
      flex: 0 0 12px;
      transition: all 0.3s ease 0s;

      @include sm-block() {
        display: inline;
      }
    }
  }

  .title {
    color: #fff;
    width: 100%;
    font-family: Gilroy;
    // background: $;
    text-align: center;
    padding: 13px 20px;
    font-size: 14px;
    line-height: 20.54px;
    font-weight: 600;
    letter-spacing: 0.05rem;

    @include sm-block() {
      border: none;
      font-size: 15px;
      color: $grayLight;
      padding: 0;
    }

    @include xs-block() {
      font-size: 10px;
      padding: 1px 9px;
    }
  }

  svg {
    @include sm-block() {
      max-width: 131px;
      gap: 1rem;
      justify-content: center;
      height: 35px;
    }
  }
}

.image {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  @include sm-block() {
    justify-content: space-between;
    padding: 0;
    order: 1;
    flex: none;
  }

  img {
    max-width: 325px;
    height: 100%;
    max-height: 75px;

    @include md-block() {
      max-height: 60px;
      max-width: 100%;
    }

    @include sm-block() {
      max-height: 38px;
    }
  }
}
