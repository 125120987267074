@import '@/app/styles/variables'
@import '@/app/styles/mixins'

.coefficient
  height: 40px
  width: 90px
  display: flex
  align-items: center
  justify-content: center
  background-color: $gray
  border-radius: 12px
  color: #fff
  font-weight: 700
  font-size: 16px
  line-height: 15px
  position: relative
  overflow: hidden
  border: none

  &.isMobi
    height: 29px
    width: 100%
    border-radius: 8px
    font-size: 14px
    font-weight: 500

  span
    position: relative
    z-index: 5
    text-align: center

  &.won
    background-color: $red

  &.win_half_1
    background-color: $red

    &::after
      content: ''
      position: absolute
      background-color: #7792b6
      right: 0
      bottom: 0
      top: 0
      width: 50%
      z-index: 1

  &.win_half_2
    background-color: $red
    &::after
      content: ''
      position: absolute
      background-color: #7792b6
      left: 0
      bottom: 0
      top: 0
      width: 50%
      z-index: 1

  &.lose_half_1
    background-color: GreenGradientDefault
    &::after
      content: ''
      position: absolute
      background-color: #7792b6
      right: 0
      bottom: 0
      top: 0
      width: 50%
      z-index: 1

  &.lose_half_2
    background-color: GreenGradientDefault
    &::after
      content: ''
      position: absolute
      background-color: #7792b6
      left: 0
      bottom: 0
      top: 0
      width: 50%
      z-index: 1

  &.loss
    background-color: $GreenGradientDefault

  &.progress
    background-color: #ffb30d
  &.refund
    background-color: #6bab2196
  &.refund_error
    background-color: #7792b6
