@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  padding-top: 12px;
  padding-bottom: 20px;

  border-bottom: 1px solid $gray;
  position: relative;
}

.body {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 14px;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 6px;
}

.row {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.right {
    justify-content: flex-end;
    font-weight: 400;
    color: $grayLight;
  }
}

.columnName {
  color: #cfcfd0;
  font-weight: 500;
}

.value {
  color: #fff;
  font-weight: 700;
  text-align: left;
  text-transform: capitalize;
}

.text {
  width: 90%;
  text-align: right;
}

.arrow {
  flex: 0 0 20px;
  transition: all 0.3s ease 0s;

  &.rotate {
    transform: rotate(180deg);
  }

  svg {
    fill: #888888;
  }
}

.button {
  background: #ff0a13 !important;
  height: 29px !important;
  font-size: 14px;
  font-weight: 500;
}
