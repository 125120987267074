@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.breadcrumbs {
  margin: 18px 0px;
  margin-bottom: 3px;
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  width: 100%;
  max-width: 1440px;
  color: #565656;

  svg {
    transform: rotate(270deg);
    height: 6px;
    width: 10px;
  }

  span {
    align-content: center;
    display: flex;
  }
}

.crumb {
  display: flex;
  gap: 5px;
  font-size: 15px;
  align-items: center;
  a {
    color: #565656;
  }
}

.lastCrumb {
  cursor: default;
  color: #ffffff !important;
}

.backPath {
  width: 30px;
  height: 20px;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-self: flex-start;

  @include xs-block() {
    @include xs-block() {
      width: 20px;
      height: 14px;

      svg {
        height: 14px;
        width: 20px;
      }
    }
  }

  .backSpace {
    width: 37px;

    @include xs-block() {
      width: 20px;
    }

    svg {
      @include xs-block() {
        width: 20px;
      }
    }
  }
}
