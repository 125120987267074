@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  width: 420px;
  border-top: 1px solid #2A2A2A;
  padding: 20px;
  display: flex;
  flex-flow: column;

  margin: 0 -20px 0 -20px;
  background: #060606;

  @include xs-block() {
    width: 100%;

    padding: 14px 0 0 0;
    margin: 0;
  }

  .value {
    height: 26px;
    background: #111112;
    border-radius: 8px;
    padding: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 11.33px;
    letter-spacing: 0.002em;
    text-align: left;
    color: white;

    @include xs-block() {
      font-weight: 600;
    }

  }

  .overall {
    border-top: 1px solid #2A2A2A;
    border-bottom: 1px solid #2A2A2A;
    color: #A4A4A4;
  }

  .winning {
    color: #D80027;
    border-bottom: 1px solid #2A2A2A;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row;
    height: 46px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.33px;
    letter-spacing: 0.002em;
    text-align: left;

    @include xs-block() {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.98px;
      letter-spacing: 0.02em;
      height: 45px;
    }
  }


  .single {
    margin-top: 0 !important;
  }

  .checkbox {
    margin-bottom: 20px;
    margin-top: 20px;
    text-transform: none;
    width: 230px;
    margin-left: 3px;

    @include xs-block() {
      margin-bottom: 14px;
      margin-top: 28px;
    }

    span {
      color: #fff;
    }
  }

  .checkbox__check {
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &.disabled {
      &:after {
        background: none !important;
      }

      &:before {
        content: none !important;
      }
    }

    &::after {
      content: '';
      height: 15px;
      width: 15px;
      border-radius: 3px;
      background: $RedGradientDefault;
      position: absolute;
      top: 0;
      left: -3.5px;
      transform: translate(0, -50%);
    }


  }
}