@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #222222;
  padding: 26px 0;
  margin-top: 26px;

  @include md-block() {
    padding-bottom: 100px;
  }

  @include xs-block() {
    padding-bottom: 80px;
  }
}

.footerZone {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 26px;

  @include xs-block() {
    padding-bottom: 0;
  }

  p {
    font-weight: $GilroyMedium;
    font-size: 14px;
    line-height: 25px;
    margin: 0;

    @include md-block() {
      font-size: 12px;
    }

    img {
      width: 20px;
      height: 20px;
      margin: 10px;
    }
  }
}

.infoBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  flex-wrap: wrap;
  justify-content: center;

  @include md-block() {
    font-size: 16px;
    gap: 3px;
  }

  span {
    font-weight: 300;
    color: $red;
  }
}

.text {
  max-width: 981px;
  font-size: 14px;
  font-weight: 400;
  font-size: 500;
  font-size: 16px;
  max-width: 856px;
}

.infoItem {
  display: flex;
  gap: 0.5rem;
  font-size: 18px;
  font-weight: 700;

  @media (any-hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }

  @include md-block() {
    font-size: 18px;
  }
}

.year {
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 3.99px;
  text-transform: uppercase;
  font-size: 14px;
}

.wrapperMobile {
  margin: 0 16px;
  margin-top: 1rem;
  padding-bottom: 76px;
  border-top: 1px solid #222222;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .text {
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    max-width: 100%;
    padding: 0;
    margin: 0;
    line-height: 20px;
  }

  .social {
    padding: 0 0 24px 0;
    border-bottom: 1px solid $red;
    display: flex;
    justify-content: center;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .year {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }
}

.linksList {
  display: flex;
  flex-direction: column;
}
