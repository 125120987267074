@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.coupon {
  display: flex;
  flex-direction: column;
  width: 420px;
  overflow: hidden;
  position: fixed;
  z-index: 10;
  bottom: 0;
  right: 0;
  transform: translateY(100%);

  @include xs-block() {
    width: 100%;
  }


  &.opened {
    transform: translateY(0);
    height: calc(100vh - 82px);
    z-index: 9999;

    @include md-block() {
      height: calc(100vh - 53px);
    }

    @include xs-block() {
      height: calc(100vh - 53px);
    }
  }
}

.couponBody {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #060606;

  @include xs-block() {
   padding: 20px 16px;
  }

  .deleteAll {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    font-size: 14px;
    font-weight: 500;
    line-height: 17.15px;
    letter-spacing: 0.002em;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    .text {
      cursor: pointer;
    }

    @include xs-block() {
      height: 36px;
      border-bottom: 1px solid #2A2A2A;
      margin-bottom: 12px;
    }

    svg {
      cursor: pointer;

      @include xs-block() {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.couponButtonWidget {
  height: 24px;
  display: flex;
  align-items: center;
  position: relative;

  svg {
    path {
      fill: $grayLight;
    }
  }
}

.count {
  border-radius: 100%;
  background: #d5070e;
  position: absolute;
  width: 12px;
  height: 12px;
  text-align: center;
  bottom: 12px;
  right: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
  font-size: 8px;
  font-weight: 700;
  line-height: 9.9px;


  &:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    padding: 1px;
    background: linear-gradient(0deg, #6B0000 0%, #FF0A13 99.97%);

    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    -webkit-background-clip: border-box;
    background-clip: text;
  }
}
