@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  display: flex;
  gap: 10px;
  color: $grayLight;
  font-size: 20px;
  font-weight: 550;
  align-items: center;
  gap: 10px;
  padding: 14px 12px;
  border-radius: 14px;
  cursor: pointer;
  border: 1px solid $black;
  position: relative;
  height: 55px;

  transition: all 0.3s ease 0s;

  &:hover {
    color: #fff;

    .icon {
      svg path {
        fill: #fff;
      }
    }
  }

  @include md-block() {
    height: 44px;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 600;
    border: none;
    color: #fff;
    gap: 8px;

    .icon {
      svg path {
        fill: $red;
      }
    }
  }

  &.active {
    border: 1px solid $red;
    cursor: default;
    color: #fff;

    .icon {
      svg path {
        fill: $red;
      }
    }
  }

  &.last {
    padding-top: 34px;

    &::after {
      content: '';
      position: absolute;
      top: 0px;
      height: 1px;
      left: 0;
      width: 100%;
      background-color: $gray;
    }
  }
}

.icon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }

  @include md-block() {
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.arrow {
  margin-left: auto;
  padding: 0 8px;

  @include xs-block() {
    svg {
      fill: #888888;
    }
  }
}
