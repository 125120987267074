@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  position: relative;
  margin-bottom: 108px;

  @include xs-block() {
    margin-bottom: 51px;
    margin-left: -16px;
    margin-right: -16px;
  }

  :global {
    .buttonBlackHoverRedBorderGold {
      position: relative;
      width: 34px;
      height: 34px;
      text-decoration: none;
      border: none;
      background: transparent;
      @include gradientBorder(100px);

      &:after {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        height: 34px;
        width: 34px;
        content: attr(data-content) !important;
        @include gradientText()
      }
    }

    .owl-dots {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 20px;
      display: flex;
      align-items: center;
      gap: 6px;
      max-width: 47px;
      width: 100%;
      justify-content: space-between;

      span {
        margin: 0 !important;
      }


      @include xs-block() {
        bottom: -18px !important;

        .owl-dot {

          &.active {
            span {
              width: 18px !important;
              height: 5px !important;
              border-radius: 25px !important;
              background: $GoldGradientDefault !important;
            }
          }

          span {
            width: 9px !important;
            height: 5px !important;
            border-radius: 25px !important;
            background: #565656 !important;


            &::after {
              display: none;
            }
          }

        }
      }
    }

    .owl-nav {
      margin-top: 0;
    }

    button {
      width: 34px;
      height: 34px;
      background: rgba(35, 31, 32, 0.6);
    }

    .owl-nav {
      position: absolute;
      bottom: -14px;
      width: 88px;
      right: 0;
      margin-top: 0 !important;

      @include xs-block() {
        display: none;
      }

      div {
        background: none !important;
        width: 34px;
        height: 34px;
        padding: 0 !important;
        position: absolute;
      }
    }

    .owl-prev {
      border: 0;
      left: 0;

      .buttonBlackHoverRedBorderGold {
        &:after {
          margin: -4px -5px -1px -6px;

        }
      }
    }

    .owl-next {
      right: 0;

      .buttonBlackHoverRedBorderGold {
        &:after {
          margin: -4px -5px -1px -4px;

        }
      }
    }
  }
}

.owl-stage {
  @include xs-block() {
    min-width: 1000px;
  }
}


.item {
  position: relative;
  height: 386px;
  display: block;
  border-radius: 16px;
  border: 1px solid #2A2A2A;

  @include xs-block() {
    height: 180px;
    min-width: 320px;
    border-radius: 0;
  }

  .image {
    border-radius: 16px;
    height: 100%;
    width: 100%;
    object-fit: cover;

    @include xs-block() {
      border-radius: 0;
    }
  }

  @include sm-block() {
    .image {
      height: 396px;
      object-fit: cover;
    }

    .foreground {
      top: 96%;
      bottom: auto;
    }
  }

  @include xs-block() {
    .image {
      height: 165px;
      object-fit: cover;
    }

    .foreground {
      top: 85%;
      bottom: auto;
    }
  }

  .foreground {
    position: absolute;
    bottom: -60%;
  }
}
