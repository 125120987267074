@import '@/app/styles/variables';
@import '@/app/styles/mixins';

.wrapper {
  background: #060606;
  border: 1px solid #2A2A2A;
  border-radius: 16px;
  padding: 20px;

  @include xs-block() {
    border: 0 !important;
    border-radius: 0 !important;
    padding: 0 0 12px 0 !important;
  }

  .content {
    display: flex;
    flex-flow: column;
    gap: 18px;

    @include xs-block() {
      gap: 4px;
    }

    .logos {
      width: 107px;
      display: flex;
      position: relative;

      :nth-child(1) {
        left: 45px;
        position: absolute;
      }
    }

    .logo {
      width: 56px;
      height: 56px;
    }

    .row {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;

      svg {
        width: 16px;
        height: 16px;
        cursor: pointer;

        @include xs-block() {
          width: 14px;
          height: 14px;

          path {
            fill: #C3C3C3;
          }
        }
      }
    }

    .col {
      display: flex;
      flex-flow: column;
      gap: 8px;
    }

    .label {
      font-size: 16px;
      font-weight: 500;
      line-height: 19.41px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #A4A4A4;

      @include xs-block() {
        font-size: 14px;
        line-height: 16.98px;
        text-align: left;
      }
    }

    .comands {
      display: flex;
      flex-flow: column;
      gap: 4px;
    }

    .comand {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.81px;
      letter-spacing: 0.02em;
      text-align: left;

      @include xs-block() {
        font-size: 14px;
        line-height: 17.33px;
      }
    }

    .value {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.81px;
      letter-spacing: 0.1em;
      text-align: right;

      @include xs-block() {
        font-size: 14px;
        font-weight: 600;
        line-height: 17.15px;
        letter-spacing: 0.02em;
        text-align: right;
      }
    }
  }
}

.mobileNotSingle {
  border-bottom: 1px solid #2A2A2A !important;


  &:last-of-type {
    border-bottom: 0 !important;
  }
}
